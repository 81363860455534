/* this piece of coded needed to shop contact page html overflowing and needing scroll
   bar when not needed.. */
html {
  height: 0;
}

.outerContainer {
  width: 100%;
  max-width: 80%;
  display: flex;
  align-items: center;
  height: 90vh;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3vh;
}

.innerContainer {
  height: auto;
  width: 80%;
}

.formContent {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  justify-items: center;
  background-color: #d2b48d;
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 35%) 0px 3px 10px 0px;
}

.contactTitle {
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  margin: auto;
  height: 10%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  color: white;
}

.picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;
}

.picture {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 85%;
  border-radius: 0.5rem;
  width: 75%;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.8rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form div:first-child {
  margin-top: 1vh;
}

.form Button {
  margin-bottom: 1vh;
}

.form div:nth-child(1),
.form div:nth-child(2),
.form div:nth-child(3),
.form div:nth-child(4) {
  padding-bottom: 3vh;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  max-width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;
}

textarea {
  resize: none;
}

.emailSubmissionMessage {
  display: flex;
  width: 80%;
  height: 80%;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
}

.picDiv {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1600px) {
  .outerContainer {
    max-width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  textarea {
    height: 7em;
  }

  .contactTitle {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1200px) {
  .picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .innerContainer {
    width: 80%;
    height: 80%;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 0;
  }

  /* .outerContainer {
    padding-bottom: 3vh;
  } */
}

@media screen and (max-width: 800px) {
  .outerContainer {
    padding-top: 2vh;
    max-width: 100%;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 0;
  }

  .picture {
    width: 100%;
    height: 25%;
    object-fit: cover;
    border-radius: 0;
  }

  .form {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 65%;
  }

  .contactTitle {
    font-size: 4.5rem;
    height: 15%;
  }
}

@media screen and (max-width: 768px) {
  .innerContainer {
    max-height: 100%;
    height: auto;
    width: 100%;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 600px) {
  .innerContainer {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .contactTitle {
    font-size: 3.5rem;
  }
}

@media (orientation: landscape) and (max-width: 1000px) {
  .innerContainer {
    height: auto;
  }

  .formContent {
    margin-bottom: 1.5rem;
  }
}
