.outerContainer {
  width: 100%;
  height: 90vh;
}

.innerContainer {
  max-height: 100%;
  height: auto;
  width: 100%;
  overflow-y: scroll;
}

.banner {
  /* border: 4px solid blue; */
  width: 100%;
  height: 70vh;
  position: relative;
}

.bannerText {
  position: absolute;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 5rem;
  left: 5%;
  top: 30%;
}

.picture {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* .mainContent {
  border: 4px solid purple;
} */

.artistStatement,
.biography {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
}

.mainContent h1 {
  padding-left: 10%;
  padding-bottom: 3%;
  padding-top: 3%;
  font-family: "Roboto Condensed", sans-serif;
}

/* .mainContent h1:first-child {
  margin-top: 3%;
} */

.h1TagStyling {
  border-left: 3px solid black;
}

h4 {
  margin-left: 0;
  margin-right: auto;
  font-family: "Roboto Condensed", sans-serif;
}

.exhibitions2021,
.exhibitions2022,
.exhibitions2023,
.exhibitions2024 {
  display: flex;
  flex-direction: column;
  margin-left: -20px;
}

@media screen and (max-width: 1600px) {
  .mainContent h1 {
    padding-bottom: 5%;
    padding-top: 5%;
  }
}

@media screen and (max-width: 1440px) {
  .banner {
    height: 35vh;
  }

  .bannerText {
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .mainContent h1 {
    padding-bottom: 6%;
    padding-top: 6%;
    font-size: 1.5rem;
  }

  .bannerText {
    font-size: 2.5rem;
    width: 50vw;
  }

  .artistStatement,
  .biography {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 430px) {
  .bannerText {
    top: 15%;
    font-size: 2rem;
  }

  .mainContent h1 {
    padding-bottom: 10%;
    padding-top: 15%;
  }
}

/* HEIGHT RESPONSIVENESS */

@media screen and (max-height: 900px) {
  .banner {
    margin-top: 5vh;
    height: 50vh;
  }
}

@media screen and (max-height: 600px) {
  .banner {
    margin-top: 15vh;
    height: 50vh;
  }
}

@media screen and (max-height: 450px) {
  .banner {
    margin-top: 25vh;
    height: 55vh;
  }
}

@media (orientation: landscape) and (max-width: 1000px) {
  .banner {
    margin-top: 5vh;
  }
}
