:nth-child(1) {
  --nth-child: 1;
}
:nth-child(2) {
  --nth-child: 2;
}
:nth-child(3) {
  --nth-child: 3;
}
:nth-child(4) {
  --nth-child: 4;
}
:nth-child(5) {
  --nth-child: 5;
}
:nth-child(6) {
  --nth-child: 6;
}
:nth-child(7) {
  --nth-child: 7;
}
:nth-child(8) {
  --nth-child: 8;
}
:nth-child(9) {
  --nth-child: 9;
}
:nth-child(10) {
  --nth-child: 10;
}
:nth-child(11) {
  --nth-child: 11;
}
:nth-child(12) {
  --nth-child: 12;
}
:nth-child(13) {
  --nth-child: 13;
}
:nth-child(14) {
  --nth-child: 14;
}
:nth-child(15) {
  --nth-child: 15;
}
:nth-child(16) {
  --nth-child: 16;
}
:nth-child(17) {
  --nth-child: 17;
}
:nth-child(18) {
  --nth-child: 18;
}
:nth-child(19) {
  --nth-child: 19;
}
:nth-child(20) {
  --nth-child: 20;
}
:nth-child(21) {
  --nth-child: 21;
}
:nth-child(22) {
  --nth-child: 22;
}

.card {
  opacity: 0;
  animation: appear 0.3s ease-out forwards;
  animation-delay: calc(var(--nth-child) * 0.1s);
}

.card:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.cardTextBody {
  background-color: #d2b48d;
}

.cardText {
  color: rgb(0, 0, 0);
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
